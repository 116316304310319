import './SupportedFormat.scss';
import docIcon from '../../../content/images/doctype/doc.png';
import xlsIcon from '../../../content/images/doctype/xls.png';
import pptIcon from '../../../content/images/doctype/ppt.png';
import pdfIcon from '../../../content/images/doctype/pdf.png';
import aiIcon from '../../../content/images/doctype/ai.png';
import inddIcon from '../../../content/images/doctype/indd.png';
import psdIcon from '../../../content/images/doctype/psd.png';
import htmlIcon from '../../../content/images/doctype/html.png';

const SupportedFormat = () => {
    return (
        <div className="supported-formats">
            <h2>対応できる原稿形式</h2>
            <h3>SUPPORTED DOCUMENT FORMATS</h3>
            <p>Word、Excel、PowerPoint、PDF、画像ファイル、Illustrator、InDesign、Photoshop、htmlなど</p>
            <p>※その他のフォーマットも対応可能な場合がありますのでお気軽にお問い合わせください。</p>
            <div className="formats-icons">
                <img src={docIcon} alt="DOC" />
                <img src={xlsIcon} alt="XLS" />
                <img src={pptIcon} alt="PPT" />
                <img src={pdfIcon} alt="PDF" />
                <img src={aiIcon} alt="AI" />
                <img src={inddIcon} alt="INDD" />
                <img src={psdIcon} alt="PSD" />
                <img src={htmlIcon} alt="HTML" />
            </div>
        </div>
    );
}

export default SupportedFormat;