import './Requirements.scss';
import req1 from '../../../content/images/recruitments/req1.png';
import req2 from '../../../content/images/recruitments/req2.png';
import req3 from '../../../content/images/recruitments/req3.png';

const Requirements = () => {
    return (
        <div className='requirements'>
            <h2>翻訳のコバケの翻訳者になるには？</h2>
            <h3>REQUIREMENTS</h3>
            <p>当社からご依頼する翻訳案件では、以下のスタンスを意識して翻訳やレビューを行っていただきます。</p>
            <p>※翻訳やレビュー業務では、品質と納期の両方を重視して、お客様のご要望にお応えすることが求められます。</p>
            <div className='req-section'>
                <div className='req'>
                    <img src={req1} alt='1' />
                    <p>原文の文法・語彙・表現を正確に把握し、<br />適切な文章に翻訳します</p>
                </div>
                <div className='req'>
                    <img src={req2} alt='2' />
                    <p>翻訳した内容が原文の意味を正確に伝えており、<br />誤訳や誤字脱字がないかを確認します</p>
                </div>
                <div className='req'>
                    <img src={req3} alt='3' />
                    <p>翻訳した内容が読みやすく、<br />自然な文章になっているかを確認します</p>
                </div>
            </div>
        </div>
    );
}

export default Requirements;