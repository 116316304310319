import './Recruitment.scss';
import recruit from '../../../content/images/translation/translators.png';

const Recruitment = () => {
    return (
        <div className="recruitment-service">
            <div className="text-content">
                <h2>
                    翻訳の専門会社である翻訳のコバケでは、 優秀な翻訳者を募集しています
                </h2>
                <p className="description">ご自身の専門分野や対応可能言語を登録していただければ、</p>
                <p className="description">それに合ったお仕事をご紹介いたします。</p>
                <p className="description">翻訳・通訳のスキルを活かして、活躍の場を広げませんか？</p>
                <p className="description">ぜひご登録をお待ちしております。</p>
            </div>
            <img src={recruit} alt="Translator Recruitment" className="service-image" />
        </div>
    );
};

export default Recruitment;
