import Banner from '../common/Banner/Banner';
import Footer from '../common/Footer/Footer';
import Header from '../common/Header/Header';
import about from '../../content/images/banners/about.png';
import './About.scss';
import Overview from './Overview/Overview';
import MissionStatement from './MissionStatement/MissionStatement';
import Mission from './Mission/Mission';

const About = () => {
    return (
        <>
            <Header />
            <Banner imagePath={about} />
            <Overview />
            <Mission />
            <MissionStatement />
            <Footer />
        </>
    )
}

export default About;