import './QuoteRequest.scss';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const QuoteRequest = () => {
    return (
        <div className='quote-request'>
            <p>翻訳のご依頼を検討されている方は、こちらのフォームをご利用ください。</p>
            <p>ご入力いただいた内容をもとに、無料でお見積もりいたします。</p>
            <button className='quote-request-button primary'>
                お見積りフォーム
                <NavigateNextIcon />
            </button>
        </div>
    );
}

export default QuoteRequest;
