import './Intro.scss';

const Intro = () => {
    return (
        <div className='intro'>
            <div className='intro-content'>
                <h2>翻訳のコバケはお客様に寄り添う身近な翻訳会社として</h2>
                <p>お客様一人ひとりの細かなニーズにできる限り丁寧にお応えし、柔軟に対応いたします。</p>
                <p>専門分野に精通した翻訳者が文意を正確に把握した上で、丁寧に翻訳します。翻訳後は、徹底した品質管理を経て高品質な翻訳をお届けします。</p>
                <p>納期厳守でリーズナブルな料金でお客様に寄り添ったサービスを展開いたします。</p>
            </div>
        </div>
    );
};

export default Intro;