import './PricingTable.scss';

const pricingData = [
    { from: '日本語', to: '英語', price: '8円〜' },
    { from: '英語', to: '日本語', price: '12円〜' },
    { from: '日本語', to: '中国語（簡体字）', price: '7円〜' },
    { from: '日本語', to: '中国語（繁体字）', price: '7円〜' },
    { from: '日本語', to: '韓国語', price: '8円〜' },
    { from: '日本語', to: 'フランス語', price: '16円〜' },
    { from: '日本語', to: 'ドイツ語', price: '16円〜' },
    { from: '日本語', to: 'イタリア語', price: '18円〜' },
    { from: '日本語', to: 'ロシア語', price: '18円〜' },
];

const PricingTable = () => {
    return (
        <div className='pricing-table'>
            <div className='pricing-desc'>
                <h2>料金体系</h2>
                <h3>PRICING</h3>
                <p>お客様のご要望や状況に合わせて、最適な料金をご提案させていただきます。</p>
                <p>お気軽にお問い合わせください。</p>
                <p className='hint'>※単価はあくまで目安です。</p>
                <p className='hint'>※単価は日文及び英語1文字(英語は1ワード)ごとの単価です。</p>
                <p className='hint'>※単価は税抜きの単価です。</p>
            </div>
            
            <table className='pricing-chart'>
                <thead>
                    <tr>
                        <th>◯◯から翻訳</th>
                        <th>◯◯に翻訳</th>
                        <th>価格</th>
                    </tr>
                </thead>
                <tbody>
                    {pricingData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.from}</td>
                            <td>{item.to}</td>
                            <td>{item.price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default PricingTable;
