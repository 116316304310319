import './App.css';
import './global.scss';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Top from './components/Top/Top';
import ServiceComponent from './components/Service/Service';
import About from './components/About/About';
import Estimate from './components/Estimate/Estimate';
import Register from './components/Register/Register';
import Privacy from './components/Privacy/Privacy';
import Contact from './components/Contact/Contact';

const THEME = createTheme({
  typography: {
    "fontFamily": `"Noto Sans JP", Helvetica, Arial, sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  }
});

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <Router>
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<ServiceComponent />} />
          <Route path="/estimate" element={<Estimate />} />
          <Route path="/register" element={<Register />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          {/* Add more routes as needed */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
