import Footer from '../common/Footer/Footer';
import Header from '../common/Header/Header';
import Banner from '../common/Banner/Banner';
import bannerImage from '../../content/images/banners/contact.png';
import './Contact.scss';
import ContactForm from './ContactForm/ContactForm';

const Contact = () => {
    return (
        <>
            <Header />
            <Banner imagePath={bannerImage} />
            <ContactForm />
            <Footer />
        </>
    )
}

export default Contact;