import Footer from '../common/Footer/Footer';
import Header from '../common/Header/Header';
import Banner from '../common/Banner/Banner';
import bannerImage from '../../content/images/banners/service.png';
import './Service.scss';
import SupportedFormat from './SupportedFormat/SupportedFormat';
import PricingTable from './PricingTable/PricingTable';
import QuoteRequest from './QuoteRequest/QuoteRequest';
import Flow from './Flow/Flow';

const Service = () => {
    return (
        <>
            <Header />
            <Banner imagePath={bannerImage} />
            <SupportedFormat />
            <PricingTable />
            <Flow />
            <QuoteRequest />
            <Footer />
        </>
    )
}

export default Service;