import './Footer.scss';
import logo from '../../../content/images/kobakelogo-white.png';
import phoneIcon from '../../../content/images/icons/tel.png';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer-content'>
                <div className='footer-menu'>
                    <div className='footer-logo' onClick={() => { window.location.href = '/' }}>
                        <img src={logo} alt='Kobake Translations Logo' />
                    </div>
                    <nav className='footer-nav-content'>
                        <ul>
                            <li><a href='/'>トップ</a></li>
                            <li><a href='/about'>会社紹介</a></li>
                            <li><a href='/services'>サービス詳細</a></li>
                        </ul>
                        <ul>
                            <li><a href='/estimate'>お見積り</a></li>
                            <li><a href='/register'>翻訳者登録</a></li>
                            <li><a href='/privacy'>プライバシーポリシー</a></li>
                        </ul>
                    </nav>
                </div>

                <div className='footer-contact'>
                    <p><img className='phone-icon' src={phoneIcon} alt='tel' style={{ height: 24, width: 24 }} /> TEL: 080-1234-5678</p>
                    <button className='contact-button' onClick={() => { window.location.href = '/contact' }}>お問い合わせする</button>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
