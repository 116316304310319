import Footer from '../common/Footer/Footer';
import Header from '../common/Header/Header';
import Banner from '../common/Banner/Banner';
import bannerImage from '../../content/images/banners/register.png';
import './Register.scss';
import Recruitment from './Recruitment/Recruitment';
import Requirements from './Requirements/Requirements';
import ApplicationForm from './ApplicationForm/ApplicationForm';

const Register = () => {
    return (
        <>
            <Header />
            <Banner imagePath={bannerImage} />
            <Recruitment />
            <Requirements />
            <ApplicationForm />
            <Footer />
        </>
    )
}

export default Register;