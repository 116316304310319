import { useState } from 'react';
import { Box, Paper } from '@mui/material';
import './ContactForm.scss';
import CustomInput from '../../common/Input/Input';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { sendRawEmail } from '../../common/Emailjs/EmailJS';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(Swal);

const ContactForm = () => {
    const [formValues, setFormValues] = useState({
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
        companyName: '',
        email: '',
        phone: '',
        inquiry: ''
    });

    const requiredFields = {
        lastName: true,
        firstName: true,
        lastNameKana: true,
        firstNameKana: true,
        companyName: true,
        email: true,
        phone: true,
        inquiry: true
    };

    const [hasErrors, setHasErrors] = useState(false);
    const [errors, setErrors] = useState({});
    const [isVerification, setIsVerification] = useState(false);

    const validateKana = (value) => {
        const kanaPattern = /^[\u30A0-\u30FF\uFF66-\uFF9F]+$/;
        return kanaPattern.test(value);
    };

    const validatePhoneNumber = (value) => {
        const phonePattern = /^0\d{9,10}$/; // Japanese phone numbers can be either 10 or 11 digits long
        return phonePattern.test(value);
    };

    const validateEmail = (value) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(value);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
        if (value) {
            setErrors({ ...errors, [name]: false });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = {};

        // Validate required fields
        Object.keys(requiredFields).forEach((key) => {
            if (requiredFields[key] && (!formValues[key] || formValues[key] === '')) {
                newErrors[key] = 'このフィールドは必須です';
            }
        });

        // Validate Kana fields
        if (!validateKana(formValues.lastNameKana)) {
            newErrors.lastNameKana = 'ふりがなはカナで入力してください';
        }
        if (!validateKana(formValues.firstNameKana)) {
            newErrors.firstNameKana = 'ふりがなはカナで入力してください';
        }

        // Validate email format
        if (!validateEmail(formValues.email)) {
            newErrors.email = '有効なメールアドレスを入力してください';
        }

        // Validate phone number
        if (!validatePhoneNumber(formValues.phone)) {
            newErrors.phone = '有効なハイフン（-）なしの日本の電話番号を入力してください';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setHasErrors(true);
            handleScrollIntoTop();
        } else {
            // Toggle to verification view
            setHasErrors(false);
            setIsVerification(true);
            handleScrollIntoTop();
        }
    };

    const handleScrollIntoTop = () => {
        const element = document.getElementById('scroll-point');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
    }

    const handleEdit = (event) => {
        event.preventDefault();
        setIsVerification(false);
        // Scroll to the top of the form
        handleScrollIntoTop();
    };

    const handleFinalSubmit = () => {
        // Prepare the message body
        const messageBody = `
            Name: ${formValues.firstName} ${formValues.lastName}
            Name (Kana): ${formValues.firstNameKana} ${formValues.lastNameKana}
            Company Name: ${formValues.companyName}
            Email: ${formValues.email}
            Phone: ${formValues.phone}
            Inquiry: ${formValues.inquiry}
        `;

        // Prepare data to send with EmailJS
        const emailData = {
            fromName: `${formValues.firstName} ${formValues.lastName}`,
            message: messageBody,
            replyTo: formValues.email,
        };

        // Send email using EmailJS
        sendRawEmail(emailData)
            .then(response => {
                console.log('SUCCESS!', response.status, response.text);
                MySwal.fire({
                    icon: 'success',
                    title: 'お問い合わせいただき、\nありがとうございます！',
                    text: '内容を確認後、今後の手順について メールにてご連絡いたします。',
                    confirmButtonText: 'ホームへ戻る >',
                    confirmButtonColor: '#F5894F',
                    willClose: () => {
                        window.location.href = '/';
                    }
                });
            })
            .catch(error => {
                console.log('FAILED...', error);
                MySwal.fire({
                    icon: 'error',
                    title: '送信失敗',
                    text: 'お問い合わせ内容の送信に失敗しました。もう一度お試しください。',
                    confirmButtonText: '閉じる',
                    confirmButtonColor: '#F5894F'
                });
            });
    };

    return (
        <Paper className='contact-form-container' elevation={3}>
            <div id='scroll-point' style={{ scrollMarginTop: '8rem' }}></div>
            <h2>お問い合わせフォーム</h2>
            <h3>INQUIRY FORM</h3>
            {isVerification && !hasErrors ? (
                <p>ご入力内容をご確認ください。</p>
            ) : (
                <p>お問い合わせは、こちらのフォームをご利用ください。</p>
            )}
            {hasErrors && (
                <p color='error' className='form-error-message'>
                    ＊＊エラーを修正してください＊＊
                </p>
            )}
            <Box component='form' onSubmit={handleSubmit} className='contact-form'>
                <CustomInput
                    label='姓'
                    name='lastName'
                    placeholder='例：山下'
                    value={formValues.lastName}
                    onChange={handleChange}
                    required
                    error={!!errors.lastName}
                    errorMessage={errors.lastName}
                    disabled={isVerification}
                />
                <CustomInput
                    label='名'
                    name='firstName'
                    placeholder='例：太郎'
                    value={formValues.firstName}
                    onChange={handleChange}
                    required
                    error={!!errors.firstName}
                    errorMessage={errors.firstName}
                    disabled={isVerification}
                />
                <CustomInput
                    label='姓 (ふりがな)'
                    name='lastNameKana'
                    placeholder='例：ヤマシタ'
                    value={formValues.lastNameKana}
                    onChange={handleChange}
                    required
                    error={!!errors.lastNameKana}
                    errorMessage={errors.lastNameKana}
                    disabled={isVerification}
                />
                <CustomInput
                    label='名 (ふりがな)'
                    name='firstNameKana'
                    placeholder='例：タロウ'
                    value={formValues.firstNameKana}
                    onChange={handleChange}
                    required
                    error={!!errors.firstNameKana}
                    errorMessage={errors.firstNameKana}
                    disabled={isVerification}
                />
                <CustomInput
                    label='会社名'
                    name='companyName'
                    placeholder='例：翻訳のコバケ'
                    value={formValues.companyName}
                    onChange={handleChange}
                    required
                    error={!!errors.companyName}
                    errorMessage={errors.companyName}
                    disabled={isVerification}
                />
                <CustomInput
                    label='メールアドレス'
                    name='email'
                    placeholder='例：kobake@translations.com'
                    value={formValues.email}
                    onChange={handleChange}
                    required
                    error={!!errors.email}
                    errorMessage={errors.email}
                    disabled={isVerification}
                />
                <CustomInput
                    label='電話番号'
                    name='phone'
                    placeholder='例：08012345678'
                    value={formValues.phone}
                    onChange={handleChange}
                    required
                    error={!!errors.phone}
                    errorMessage={errors.phone}
                    disabled={isVerification}
                />
                <CustomInput
                    label='ご相談・お問い合わせ内容'
                    name='inquiry'
                    type='textarea'
                    placeholder='ご相談・お問い合わせ内容を入力してください。'
                    value={formValues.inquiry}
                    onChange={handleChange}
                    required
                    error={!!errors.inquiry}
                    errorMessage={errors.inquiry}
                    disabled={isVerification}
                />
                <div className='contact-btn-container'>
                    {!isVerification ? (
                        <button className='primary' onClick={handleSubmit}>
                            この内容で確認する <NavigateNextIcon />
                        </button>
                    ) : (
                        <>
                            <button className='secondary' onClick={handleEdit}>
                                <NavigateBeforeIcon /> 戻る
                            </button>
                            <button className='primary' onClick={handleFinalSubmit}>
                                この内容で送信する <NavigateNextIcon />
                            </button>
                        </>
                    )}
                </div>
            </Box>
        </Paper>
    );
};

export default ContactForm;
