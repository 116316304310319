import './MissionStatement.scss';

const MissionStatement = () => {
    return (
        <div className='mission-statement'>
            <div className='mission-statement-content'>
                <h2>我々はいつでも信頼できる翻訳の伴走パートナー<br></br>言葉の難関を突破するなら、翻訳のコバケ</h2>
                <p>翻訳で人と人のコミュニケーションをサポートする</p>
                <p>翻訳で世界との架け橋になる</p>
                <p>翻訳のエキスパートとして最高の翻訳をお届けする</p>
            </div>
        </div>
    );
}

export default MissionStatement;
