import Footer from '../common/Footer/Footer';
import Header from '../common/Header/Header';
import Banner from '../common/Banner/Banner';
import bannerImage from '../../content/images/banners/privacy.png';
import one from '../../content/images/service/numbers/one.png';
import two from '../../content/images/service/numbers/two.png';
import three from '../../content/images/service/numbers/three.png';
import four from '../../content/images/service/numbers/four.png';
import five from '../../content/images/service/numbers/five.png';
import six from '../../content/images/service/numbers/six.png';
import seven from '../../content/images/service/numbers/seven.png';
import eight from '../../content/images/service/numbers/eight.png';
import './Privacy.scss';

const Privacy = () => {
    return (
        <>
            <Header />
            <Banner imagePath={bannerImage} />
            <div className='privacy-policy'>
                <div className='heading'>
                    <h2>翻訳のコバケのプライバシーポリシー</h2>
                    <h3>PRIVACY POLICY</h3>
                </div>
                <div className='content'>
                    <p>株式会社◯◯（以下「当社」といいます）は、当社が取得した個人情報の取扱いに関し、個人情報の保護に関する法律、個人情報保護に関するガイドライン等の指針、その他個人情報保護に関する関係法令を遵守します。</p>
                    <div className='policy-contanier'>
                        <div className='policy-item'>
                            <img src={one} alt='one' />
                            <div className='policy-content'>
                                <h5>取得する情報およびその取得方法、利用目的</h5>
                                <p>
                                    当社が取得するユーザー情報は、取得方法に応じて以下のとおりとなります。<br />（１）ユーザーから直接取得する情報と取得方法<br />当社は、当社が提供するインターネットサイト（以下「本サイト」といいます）の運営に必要な範囲で、本サイトの利用者（以下「ユーザー」といいます）から、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人時期別情報）（以下「個人情報」といいます）を取得することがあります。<br />（２）情報の利用目的<br />当社は、取得した個人情報を以下に定める目的のために使用します。<br />※ここでは、商品・サービスの提供、利用料金の請求、ユーザーの本人確認など、個人情報の利用目的を記載します。<br />※利用目的の変更についても、予め規定に入れておいた方が良いでしょう。
                                </p>
                            </div>
                        </div>

                        <div className='policy-item'>
                            <img src={two} alt='two' />
                            <div className='policy-content'>
                                <h5>個人情報の管理</h5>
                                <p>
                                    当社は、ユーザーから取得した個人情報の管理について、以下を徹底します。<br />（1）情報の正確性の確保<br />取得した個人情報については、常に正確かつ最新の情報となるよう努めます。<br />（2）安全管理措置<br />当社は、組織的な個人情報の管理については、社内規定による厳重な取扱方法を規定し、規定に基づいた取扱いと厳格な運用を徹底しています。<br />（3）個人情報管理の委託先の監督<br />個人情報の管理を外部委託する場合には、当社の規程に基づく委託先にのみ委託し適切に管理します。<br />（4）個人情報の保存期間と廃棄<br />取得した個人情報は、保存期間を設定し、保存期間終了後は廃棄します。
                                </p>
                            </div>
                        </div>

                        <div className='policy-item'>
                            <img src={three} alt='three' />
                            <div className='policy-content'>
                                <h5>個人情報の第三者への提供</h5>
                                <p>
                                    当社は、取得した個人情報を、第三者に提供することはありません。また、今後、第三者提供を行う場合は、提供する情報と目的を提示し、ユーザーの同意を得た場合のみ行います。<br />※現在個人情報の第三者提供を行っていない場合も、将来の可能性に備えて記載しておきましょう。
                                </p>
                            </div>
                        </div>

                        <div className='policy-item'>
                            <img src={four} alt='four' />
                            <div className='policy-content'>
                                <h5>個人情報の共同利用</h5>
                                <p>
                                    当社は、ユーザーの個人情報に関して、以下のとおり共同利用します。
                                </p>
                            </div>
                        </div>

                        <div className='policy-item'>
                            <img src={five} alt='five' />
                            <div className='policy-content'>
                                <h5>個人情報の開示・訂正・利用停止</h5>
                                <p>
                                    個人情報について、開示、訂正、利用停止等のお申し出があった場合には、本人の申し出であることを確認の上、当社所定の方法に基づき対応致します。具体的な方法は、個別にご案内しますので、下記受付窓口までお問い合わせください。
                                </p>
                            </div>
                        </div>

                        <div className='policy-item'>
                            <img src={six} alt='six' />
                            <div className='policy-content'>
                                <h5>お問い合わせ先</h5>
                                <p>
                                    本サービス、個人情報の取扱いについては、以下の窓口にご連絡ください。<br />※苦情や問合せに対応する窓口について、問合せ可能な連絡先と連絡方法を記しておきます。
                                </p>
                            </div>
                        </div>

                        <div className='policy-item'>
                            <img src={seven} alt='seven' />
                            <div className='policy-content'>
                                <h5>セキュリティ</h5>
                                <p>
                                    当社は、ウェブサイト経由で、SSLによって個人情報を取得することがあります。
                                </p>
                            </div>
                        </div>

                        <div className='policy-item'>
                            <img src={eight} alt='eight' />
                            <div className='policy-content'>
                                <h5>制定日日、改定</h5>
                                <p>
                                    制定日：2024年 04月 01日<br />改定日：2024年 06月 01日
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Privacy;