import './Banner.scss';

const Banner = ({ imagePath }) => {
    return (
        <>
            <div className="banner">
                <img src={imagePath} alt='about' />
            </div>
        </>
    )
}

export default Banner;