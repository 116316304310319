import { useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import './EstimateForm.scss';
import CustomInput from '../../common/Input/Input';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { sendEmailWithTemplate } from '../../common/Emailjs/EmailJS';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(Swal);
const translationLanguages = [
    { value: '英語', label: '英語' },
    { value: '中国語（簡体字）', label: '中国語（簡体字）' },
    { value: '中国語（繁体字）', label: '中国語（繁体字）' },
    { value: '韓国語', label: '韓国語' },
    { value: 'フランス語', label: 'フランス語' },
    { value: 'スペイン語', label: 'スペイン語' },
    { value: 'ドイツ語', label: 'ドイツ語' },
    { value: 'イタリア語', label: 'イタリア語' },
    { value: 'ロシア語', label: 'ロシア語' },
    { value: 'その他の言語', label: 'その他の言語' },
];

const EstimateForm = () => {
    const [formValues, setFormValues] = useState({
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
        companyName: '',
        department: '',
        email: '',
        emailConfirmation: '',
        phone: '',
        sourceLanguage: '',
        targetLanguage: '',
        date: '',
        file: null,
        comments: ''
    });

    const requiredFields = {
        lastName: true,
        firstName: true,
        lastNameKana: true,
        firstNameKana: true,
        email: true,
        emailConfirmation: true,
        phone: true,
        sourceLanguage: true,
        targetLanguage: true,
        date: true,
        file: true,
    };

    const [hasErrors, setHasErrors] = useState(false);
    const [errors, setErrors] = useState({});
    const [isVerification, setIsVerification] = useState(false);

    const handleChange = (event) => {
        const { name, value, files } = event.target;
        setFormValues({ ...formValues, [name]: files ? files[0] : value });
        if (value) {
            setErrors({ ...errors, [name]: false });
        }
    };

    const validateKana = (value) => {
        const kanaPattern = /^[\u30A0-\u30FF\uFF66-\uFF9F]+$/;
        return kanaPattern.test(value);
    };

    const validatePhoneNumber = (value) => {
        const phonePattern = /^0\d{9,10}$/; // Japanese phone numbers can be either 10 or 11 digits long
        return phonePattern.test(value);
    };

    const validateFile = (file) => {
        const allowedExtensions = ['xlsx', 'xlsm', 'xls', 'doc', 'docx', 'docm', 'tex', 'ppt', 'pptx', 'pptm', 'zip', 'pdf', 'jpg', 'jpeg', 'gif', 'png'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        return allowedExtensions.includes(fileExtension);
    };

    const validateEmail = (value) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = {};

        // Validate required fields
        Object.keys(requiredFields).forEach((key) => {
            if (requiredFields[key] && (!formValues[key] || formValues[key] === '')) {
                newErrors[key] = 'このフィールドは必須です';
            }
        });

        // Validate Kana fields
        if (!validateKana(formValues.lastNameKana)) {
            newErrors.lastNameKana = 'ふりがなはカナで入力してください';
        }
        if (!validateKana(formValues.firstNameKana)) {
            newErrors.firstNameKana = 'ふりがなはカナで入力してください';
        }

        // Validate email format
        if (!validateEmail(formValues.email)) {
            newErrors.email = '有効なメールアドレスを入力してください';
        }

        // Validate email confirmation
        if (formValues.email !== formValues.emailConfirmation) {
            newErrors.emailConfirmation = 'メールアドレスが一致しません';
        }

        // Validate phone number
        if (!validatePhoneNumber(formValues.phone)) {
            newErrors.phone = '有効なハイフン（-）なしの日本の電話番号を入力してください';
        }

        // Validate file type
        if (formValues.file && !validateFile(formValues.file)) {
            newErrors.file = '有効なファイル形式をアップロードしてください';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setHasErrors(true);
            handleScrollIntoTop();
        } else {
            // Toggle to verification view
            setHasErrors(false);
            setIsVerification(true);
            handleScrollIntoTop();
        }
    };

    const handleScrollIntoTop = () => {
        const element = document.getElementById('scroll-point');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
    }

    const handleEdit = (event) => {
        event.preventDefault();
        setIsVerification(false);
        // Scroll to the top of the form
        handleScrollIntoTop();
    };

    const handleFinalSubmit = () => {
        // Handle final form submission
        // Prepare data to send with EmailJS
        const emailData = {
            ...formValues
        };

        // Send email using EmailJS
        sendEmailWithTemplate(emailData, 'estimate')
            .then(response => {
                console.log('SUCCESS!', response.status, response.text);
                MySwal.fire({
                    icon: 'success',
                    title: 'お見積りの送信が完了しました。\nありがとうございます！',
                    text: '内容を確認後、今後の手順について メールにてご連絡いたします。',
                    confirmButtonText: 'ホームへ戻る >',
                    confirmButtonColor: '#F5894F',
                    willClose: () => {
                        window.location.href = '/';
                    }
                });
            })
            .catch(error => {
                console.log('FAILED...', error);
                MySwal.fire({
                    icon: 'error',
                    title: '送信失敗',
                    text: '内容の送信に失敗しました。もう一度お試しください。',
                    confirmButtonText: '閉じる',
                    confirmButtonColor: '#F5894F'
                });
            });

    };

    return (
        <>
            <div id='scroll-point'></div>
            <Paper className='estimate-form-container' elevation={3} id='estimate-form'>
                <h2>お見積りフォーム</h2>
                <h3>QUOTATION FORM</h3>
                {isVerification && (
                    <p className='form-verify-msg'>ご入力内容をご確認ください。</p>
                )}
                {hasErrors && (
                    <p color='error' className='form-error-message'>
                        ＊＊エラーを修正してください＊＊
                    </p>
                )}
                <Box component='form' onSubmit={handleSubmit} className='estimate-form'>
                    <CustomInput
                        label='姓'
                        name='lastName'
                        id='lastName'
                        placeholder='例：太郎'
                        value={formValues.lastName}
                        onChange={handleChange}
                        required
                        error={!!errors.lastName}
                        errorMessage={errors.lastName}
                        disabled={isVerification}
                    />
                    <CustomInput
                        label='名'
                        name='firstName'
                        id='firstName'
                        placeholder='例：太郎'
                        value={formValues.firstName}
                        onChange={handleChange}
                        required
                        error={!!errors.firstName}
                        errorMessage={errors.firstName}
                        disabled={isVerification}
                    />
                    <CustomInput
                        label='姓 (ふりがな)'
                        name='lastNameKana'
                        id='lastNameKana'
                        placeholder='例：ヤマシタ'
                        value={formValues.lastNameKana}
                        onChange={handleChange}
                        required
                        error={!!errors.lastNameKana}
                        errorMessage={errors.lastNameKana}
                        disabled={isVerification}
                    />
                    <CustomInput
                        label='名 (ふりがな)'
                        name='firstNameKana'
                        id='firstNameKana'
                        placeholder='例：タロウ'
                        value={formValues.firstNameKana}
                        onChange={handleChange}
                        required
                        error={!!errors.firstNameKana}
                        errorMessage={errors.firstNameKana}
                        disabled={isVerification}
                    />
                    <CustomInput
                        label='会社名'
                        name='companyName'
                        id='companyName'
                        placeholder='例：翻訳のコバケ'
                        value={formValues.companyName}
                        onChange={handleChange}
                        disabled={isVerification}
                    />
                    <CustomInput
                        label='所属部署'
                        name='department'
                        id='department'
                        placeholder='例：マーケティング部'
                        value={formValues.department}
                        onChange={handleChange}
                        disabled={isVerification}
                    />
                    <CustomInput
                        label='メールアドレス'
                        name='email'
                        id='email'
                        placeholder='例：kobake@translations.com'
                        value={formValues.email}
                        onChange={handleChange}
                        required
                        error={!!errors.email}
                        errorMessage={errors.email}
                        disabled={isVerification}
                    />
                    <CustomInput
                        label='メールアドレス (確認用)'
                        name='emailConfirmation'
                        id='emailConfirmation'
                        placeholder='例：kobake@translations.com'
                        value={formValues.emailConfirmation}
                        onChange={handleChange}
                        required
                        error={!!errors.emailConfirmation}
                        errorMessage={errors.emailConfirmation}
                        disabled={isVerification}
                    />
                    <CustomInput
                        label='電話番号'
                        name='phone'
                        id='phone'
                        placeholder='例：08012345678'
                        value={formValues.phone}
                        onChange={handleChange}
                        required
                        error={!!errors.phone}
                        errorMessage={errors.phone}
                        disabled={isVerification}
                    />
                    <div className='dual-input'>
                        <label className='dual-input-label'>翻訳言語<span className='required'>*</span></label>
                        <CustomInput
                            label='原文の言語'
                            name='sourceLanguage'
                            id='sourceLanguage'
                            type='select'
                            placeholder='例：英語'
                            value={formValues.sourceLanguage}
                            onChange={handleChange}
                            required
                            error={!!errors.sourceLanguage}
                            errorMessage={errors.sourceLanguage}
                            options={translationLanguages}
                            disabled={isVerification}
                        />
                        <CustomInput
                            label='翻訳後の言語'
                            name='targetLanguage'
                            id='targetLanguage'
                            type='select'
                            placeholder='例：日本語'
                            value={formValues.targetLanguage}
                            onChange={handleChange}
                            required
                            error={!!errors.targetLanguage}
                            errorMessage={errors.targetLanguage}
                            options={translationLanguages}
                            disabled={isVerification}
                        />
                    </div>
                    <CustomInput
                        label='ご希望の納期'
                        type='date'
                        name='date'
                        id='date'
                        placeholder='日付を選択してください'
                        value={formValues.date}
                        onChange={handleChange}
                        required
                        error={!!errors.date}
                        errorMessage={errors.date}
                        disabled={isVerification}
                    />
                    <CustomInput
                        label='ご依頼原稿'
                        type='file'
                        name='file'
                        id='file'
                        value={formValues.file}
                        onChange={handleChange}
                        required
                        error={!!errors.file}
                        errorMessage={errors.file}
                        disabled={isVerification}
                    />
                    <Typography variant='body2' className='file-notes'>
                        【注意事項】
                        <br />
                        ※対応ファイル形式は（.xlsx, .xlsm, .xls, .doc, .docx, .docm, .tex, .ppt, .pptx, .pptm, .zip, .pdf, .jpg, .jpeg, .gif, .png）となります。
                        <br />
                        ※１ファイルの容量の上限は5MBとなっております。1ファイルが5MBを超える際はメールアドレス、または記載の電話番号までご連絡下さい。
                        <br />
                        ※ファイルを添付する際は、必ずファイルを閉じてから『ファイル選択』をしてくださるようお願い致します。ファイルを開いているとデータは送付されません。
                        <br />
                        ※複数のファイルをお送りいただく場合は、可能な限りzip形式にて圧縮してお送りください。なお一つのzipファイルは5MB以下になるようにお願いいたします。
                        <br />
                        ※圧縮なしで複数のファイルをお送りいただく場合も、合計で12MB以下になるようにお願いいたします。
                        <br />
                        ※合計で12MBを超える際はメールアドレス、または記載の電話番号までご連絡下さい。
                        <br />
                        ※翻訳・校閲をご希望される原稿は、Microsoft OfficeのWord、Excel、PowerPoint、または、Tex、LaTeXの形式で保存されたファイルでお送りください。それ以外の形式のファイルの校閲をご希望される際は、コーディネーターにご相談ください。
                    </Typography>
                    <CustomInput
                        label='コメントや補足事項など'
                        name='comments'
                        type='textarea'
                        placeholder='質問事項や確認事項、補足説明等があればこちらにご記入ください。'
                        value={formValues.comments}
                        onChange={handleChange}
                        disabled={isVerification}
                        id='comments'
                    />
                    <div className='estimate-btn-container'>
                        {!isVerification ? (
                            <button className='primary' onClick={handleSubmit}>
                                内容を確認する <NavigateNextIcon />
                            </button>
                        ) : (
                            <>
                                <button className='secondary' onClick={handleEdit}>
                                    <NavigateBeforeIcon /> 戻る
                                </button>
                                <button className='primary' onClick={handleFinalSubmit}>
                                    この内容で送信する <NavigateNextIcon />
                                </button>
                            </>
                        )}
                    </div>
                </Box>
            </Paper>
        </>

    );
};

export default EstimateForm;
