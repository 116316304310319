import './Translation.scss';
import translationServiceImage from '../../../content/images/translation/translationService.png';

const TranslationTopComponent = () => {
    return (
        <div className="translation-service">
            <div className="text-content">
                <h2>
                    顧客中心主義に基づく、ニーズを理解し、バリアを排除する高品質翻訳サービス
                </h2>
                <p className="description">
                    お客様一人ひとりの細かなニーズやご希望を丁寧にヒアリングし、柔軟に対応いたします。専門分野に精通した翻訳者が文章を正確に把握した上で、丁寧に翻訳します。翻訳後は、徹底した品質管理を経て高品質な翻訳をお届けいたします。納期厳守とリーズナブルな料金でお客様に寄り添ったサービスを展開いたします。
                </p>
                <p className="description">
                    お客様にとって一番身近な外国語パートナーとして上質な翻訳サービスで言語によるバリアを排除し、スムーズなコミュニケーションを支援いたします。
                </p>
            </div>
            <img src={translationServiceImage} alt="Translation Service" className="service-image" />
        </div>
    );
};

export default TranslationTopComponent;
