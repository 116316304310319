import { send } from 'emailjs-com';

const sendEmailWithTemplate = async (templateParams, templateName = '') => {
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    let templateId = process.env.REACT_APP_EMAILJS_ESTIMATE_TEMPLATE_ID;
    if (templateName === 'estimate') {
        templateId = process.env.REACT_APP_EMAILJS_ESTIMATE_TEMPLATE_ID;
    } else if (templateName === 'register') {
        templateId = process.env.REACT_APP_EMAILJS_REGISTER_TEMPLATE_ID;
    }
    const userId = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
    return send(serviceId, templateId, templateParams, userId)
        .then(response => {
            return response;
        })
        .catch(error => {
            throw error;
        });
};

const sendRawEmail = async (emailData) => {
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const userId = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
    let templateId = process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID;

    const templateParams = {
        from_name: emailData.fromName,
        to_name: 'contact@kobake.co.jp',
        message: emailData.message,
        reply_to: emailData.replyTo,
    };

    return send(serviceId, templateId, templateParams, userId)
        .then(response => {
            return response;
        })
        .catch(error => {
            throw error;
        });
};

export { sendEmailWithTemplate, sendRawEmail };
