import './Overview.scss';

const companyInfo = [
    { label: '会社名', value: '翻訳のコバケ' },
    { label: '所在地', value: '〒123-4567 神奈川県横浜市横浜区横浜町1-2-3横浜ビル' },
    { label: '電話番号', value: 'TEL 08-1234-5678' },
    { label: '代表者名', value: '山本 太郎' },
    { label: '事業内容', value: '翻訳・通訳サービス' },
    { label: '設立年月日', value: '2024年01月01日' },
];
const Overview = () => {
    return (
        <>
            <div className="company-overview">
                <h2>会社概要</h2>
                <h3>COMPANY OVERVIEW</h3>
                <table>
                    <tbody>
                        {companyInfo.map((item, index) => (
                            <tr key={index}>
                                <td className='label'>{item.label}</td>
                                <td className='value'>{item.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Overview;