import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import background1 from '../../../content/images/background1.png';
import tab1_1 from '../../../content/images/service/tab1-1.png';
import tab1_2 from '../../../content/images/service/tab1-2.png';
import tab1_3 from '../../../content/images/service/tab1-3.png';
import tab2_1 from '../../../content/images/service/tab2-1.png';
import tab2_2 from '../../../content/images/service/tab2-2.png';
import tab2_3 from '../../../content/images/service/tab2-3.png';
import tab3_1 from '../../../content/images/service/tab3-1.png';
import tab3_2 from '../../../content/images/service/tab3-2.png';
import number_1 from '../../../content/images/service/numbers/one.png';
import number_2 from '../../../content/images/service/numbers/two.png';
import number_3 from '../../../content/images/service/numbers/three.png';
import eng from '../../../content/images/languages/eng.png';
import jp from '../../../content/images/languages/jp.png';
import cn from '../../../content/images/languages/cn.png';
import kor from '../../../content/images/languages/kor.png';
import vet from '../../../content/images/languages/vet.png';
import thai from '../../../content/images/languages/thai.png';
import ger from '../../../content/images/languages/ger.png';
import bra from '../../../content/images/languages/bra.png';
import fra from '../../../content/images/languages/fra.png';
import esp from '../../../content/images/languages/esp.png';
import rus from '../../../content/images/languages/rus.png';
import serviceArea_1 from '../../../content/images/service/areas/1.png';
import serviceArea_2 from '../../../content/images/service/areas/2.png';
import serviceArea_3 from '../../../content/images/service/areas/3.png';
import serviceArea_4 from '../../../content/images/service/areas/4.png';
import serviceArea_5 from '../../../content/images/service/areas/5.png';
import serviceArea_6 from '../../../content/images/service/areas/6.png';
import serviceArea_7 from '../../../content/images/service/areas/7.png';
import serviceArea_8 from '../../../content/images/service/areas/8.png';
import serviceArea_9 from '../../../content/images/service/areas/9.png';
import serviceArea_10 from '../../../content/images/service/areas/10.png';
import './Service.scss';

const TabContent1 = () => (
    <div className='tab-content-inner'>
        <div className='tab-item'>
            <div className='tab-item-content'>
                <div className='tab-item-content-img'>
                    <img src={tab1_1} />
                </div>
                <div className='tab-item-content-txt'>
                    <div className='content-header'>
                        <img src={number_1} />
                        <h4>専門分野に精通したカスタム翻訳サービス</h4>
                    </div>
                    <p>専門分野に精通した翻訳者が、お客様のニーズに合わせて最適な翻訳を提供いたします。</p>
                </div>
            </div>
        </div>
        <div className='tab-item'>
            <div className='tab-item-content'>
                <div className='tab-item-content-img'>
                    <img src={tab1_2} />
                </div>
                <div className='tab-item-content-txt'>
                    <div className='content-header'>
                        <img src={number_2} />
                        <h4>品質管理と翻訳者の連携</h4>
                    </div>
                    <p>翻訳前に、品質管理担当者が翻訳者と共に翻訳スタイルや注意事項などを入念に確認いたします。</p>
                </div>
            </div>
        </div>
        <div className='tab-item'>
            <div className='tab-item-content'>
                <div className='tab-item-content-img'>
                    <img src={tab1_3} />
                </div>
                <div className='tab-item-content-txt'>
                    <div className='content-header'>
                        <img src={number_3} />
                        <h4>品質管理による翻訳の最終確認</h4>
                    </div>
                    <p>翻訳の途中や翻訳後に品質管理担当者がレビューを行い、誤訳や不自然な表現を徹底的に排除し、高品質な翻訳として仕上げます。</p>
                </div>
            </div>
        </div>
    </div>
);

// Create similar components for TabContent2, TabContent3, TabContent4, and TabContent5
const TabContent2 = () => (
    <div className='tab-content-inner'>
        <div className='tab-item'>
            <div className='tab-item-content'>
                <div className='tab-item-content-img'>
                    <img src={tab2_1} />
                </div>
                <div className='tab-item-content-txt'>
                    <div className='content-header'>
                        <img src={number_1} />
                        <h4>翻訳と制作のワンストップサービス</h4>
                    </div>
                    <p>翻訳と制作の両方に精通したスタッフが揃えており、翻訳する文書やコンテンツを、お客様のご希望に沿って、最適な形式やデザインで
仕上げさせていただきます。</p>
                </div>
            </div>
        </div>
        <div className='tab-item'>
            <div className='tab-item-content'>
                <div className='tab-item-content-img'>
                    <img src={tab2_2} />
                </div>
                <div className='tab-item-content-txt'>
                    <div className='content-header'>
                        <img src={number_2} />
                        <h4>文書のレイアウトと編集サービス</h4>
                    </div>
                    <p>翻訳した文書を、印刷物やお客様の希望するフォーマットに適したレイアウトやフォントで整え、また画像や図表などの挿入や編集も
行います。</p>
                </div>
            </div>
        </div>
        <div className='tab-item'>
            <div className='tab-item-content'>
                <div className='tab-item-content-img'>
                    <img src={tab2_3} />
                </div>
                <div className='tab-item-content-txt'>
                    <div className='content-header'>
                        <img src={number_3} />
                        <h4>広範なファイル形式への対応</h4>
                    </div>
                    <p>PDFやWordなどの一般的なファイル形式はもちろん、InDesignやIllustrator、Photoshopなどの専門的なソフトウェアにも
対応しております。</p>
                </div>
            </div>
        </div>
    </div>
);

const TabContent3 = () => (
    <div className='tab-content-inner'>
        <div className='tab-item'>
            <div className='tab-item-content'>
                <div className='tab-item-content-img'>
                    <img src={tab3_1} />
                </div>
                <div className='tab-item-content-txt'>
                    <div className='content-header'>
                        <img src={number_1} />
                        <h4>ウェブサイト作成と多言語化のトータルサポート</h4>
                    </div>
                    <p>ウェブページ作成や外国語化も対応しており、翻訳したコンテンツを、そのままホームページのレイアウトに沿って最適なコーディングやデザインを行います。</p>
                </div>
            </div>
        </div>
        <div className='tab-item'>
            <div className='tab-item-content'>
                <div className='tab-item-content-img'>
                    <img src={tab3_2} />
                </div>
                <div className='tab-item-content-txt'>
                    <div className='content-header'>
                        <img src={number_2} />
                        <h4>外国語ウェブサイトの提案</h4>
                    </div>
                    <p>外国語のウェブサイトにふさわしいレイアウトや掲載情報の提案も可能です。</p>
                </div>
            </div>
        </div>
    </div>
);

const TabContent4 = () => (
    <div className='tab-content-inner'>
        <div className='tab-item-images'>
            <img src={eng} />
            <img src={jp} />
            <img src={cn} />
            <img src={kor} />
            <img src={vet} />
            <img src={thai} />
            <img src={ger} />
            <img src={bra} />
            <img src={fra} />
            <img src={esp} />
            <img src={rus} />
        </div>
    </div>
);

const TabContent5 = () => (
    <div className='tab-content-inner'>
        <div className='tab-item-images'>
            <img src={serviceArea_1} />
            <img src={serviceArea_2} />
            <img src={serviceArea_3} />
            <img src={serviceArea_4} />
            <img src={serviceArea_5} />
            <img src={serviceArea_6} />
            <img src={serviceArea_7} />
            <img src={serviceArea_8} />
            <img src={serviceArea_9} />
            <img src={serviceArea_10} />
        </div>
    </div>
);

const tabs = [
    { label: '専門性の高い翻訳＆徹底した品質管理', content: <TabContent1 /> },
    { label: 'DTP調整もしっかり対応', content: <TabContent2 /> },
    { label: '外国語のウェブページ作成もお任せ', content: <TabContent3 /> },
    { label: '対応できる言語', content: <TabContent4 /> },
    { label: '対応できる分野', content: <TabContent5 /> }
];

const Service = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <div className='services-tabs' style={{ backgroundImage: `url(${background1})` }}>
            <div className='services-tabs-content'>
                <Typography variant='h4'>弊社の提供サービスの特徴</Typography>
                <Typography variant='h6' gutterBottom>SERVICES</Typography>
                <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons='auto'
                    aria-label='services tabs'
                    className='tabs'
                >
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} />
                    ))}
                </Tabs>
                <Box className='tab-content'>
                    {tabs[activeTab].content}
                </Box>
            </div>
        </div>
    );
};

export default Service;
