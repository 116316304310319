import './Contact.scss';

const TopContact = () => {
    return (
        <div className='contact-us'>
            <p>お気軽にお問い合わせください。</p>
            <p>私たちは、あなたの声をお待ちしています！</p>
            <button className='contact-button primary' onClick={() => { window.location.href = '/contact' }}>お問い合わせする</button>
        </div>
    );
};

export default TopContact;
