import './Mission.scss';
import one from '../../../content/images/service/numbers/one.png';
import two from '../../../content/images/service/numbers/two.png';
import three from '../../../content/images/service/numbers/three.png';

const missions = [
    {
        title: '言葉のバリアを打ち壊し、ビジネスゴールの達成を支援',
        description: '翻訳・通訳を通して、言語の壁をなくし、世界中の人々が交流できるようにすることを目指しています。',
        imagePath: one
    },
    {
        number: '02',
        title: '言葉の山を乗り越え、異文化の交流を深化',
        description: '翻訳・通訳を通して、海外と日本の距離を縮め、グローバルな交流を促進します。',
        imagePath: two
    },
    {
        number: '03',
        title: '言葉の縄張りから抜け出し、新たな人文価値を共創',
        description: '翻訳・通訳サービスを通して、人と人をつなぎ、新たな価値を創造することを目指しています。',
        imagePath: three
    }
];

const Mission = () => {
    return (
        <div className='mission'>
            <h2>経営理念やビジョンなど</h2>
            <h3>MISSION & VISION</h3>
            {missions.map((mission, index) => (
                <div className='mission-item' key={index}>
                    <img src={mission.imagePath} alt={`Mission ${mission.number}`} />
                    <div className='mission-content'>
                        <h5>{mission.title}</h5>
                        <p>{mission.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Mission;
