import Slider from 'react-slick';
import './Slider.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slideLeft from '../../../content/images/slider/silder-left.png';
import slideMiddle from '../../../content/images/slider/slider-middle.png';
import slideRight from '../../../content/images/slider/slider-right.png';
const SliderComponent = () => {
    const settings = {
        className: "center",
        dots: true,
        autoplay: true,
        // centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 500
    };

    // const settings = {
    //     className: "center",
    //     centerMode: true,
    //     infinite: true,
    //     centerPadding: "60px",
    //     slidesToShow: 3,
    //     speed: 500,
    //     nextArrow: <SampleNextArrow />,
    //     prevArrow: <SamplePrevArrow />,
    // };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                <div className="slide">
                    <img src={slideLeft} alt="Slide 1" />
                </div>
                <div className="slide">
                    <img src={slideMiddle} alt="Slide 2" />
                </div>
                <div className="slide">
                    <img src={slideRight} alt="Slide 3" />
                </div>
            </Slider>
        </div>
    );
};

export default SliderComponent;