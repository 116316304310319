import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';
import Logo from '../../../content/images/kobakelogo.png';
import TopIcon from '../../../content/images/icons/top.png';
import AboutIcon from '../../../content/images/icons/info.png';
import ServicesIcon from '../../../content/images/icons/services.png';
import EstimateIcon from '../../../content/images/icons/estimate.png';
import RegisterIcon from '../../../content/images/icons/register.png';
// import PrivacyIcon from '../../../content/images/icons/privacy.png';
import ContactIcon from '../../../content/images/icons/inquiry.png';
import './Header.scss';

const menuItems = [
  { label: 'トップ', icon: TopIcon, path: '/' },
  { label: '会社紹介', icon: AboutIcon, path: '/about' },
  { label: 'サービス詳細', icon: ServicesIcon, path: '/services' },
  { label: 'お見積り', icon: EstimateIcon, path: '/estimate' },
  { label: '翻訳者登録', icon: RegisterIcon, path: '/register' },
  // { label: 'プライバシーポリシー', icon: PrivacyIcon, path: '/privacy' },
  { label: 'お問い合わせ', icon: ContactIcon, path: '/contact' }
];

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const location = useLocation();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem button key={index} component="a" href={item.path} className={location.pathname === item.path ? 'active' : ''}>
            <ListItemIcon><img src={item.icon} alt={item.label} style={{ height: 24, width: 24 }} /></ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position='fixed' color='default' className='kobake-header'>
        <Toolbar className='header-content'>
          <Box className='logo' onClick={() => { window.location.href = '/' }}>
            <img src={Logo} alt='Kobake Translations' style={{ height: 60 }} />
          </Box>
          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box className='menu-items'>
              {menuItems.map((item, index) => (
                <Box key={index} className={`menu-item ${location.pathname === item.path ? 'active' : ''}`}>
                  <a href={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img src={item.icon} alt={item.label} style={{ height: 24, width: 24 }} />
                    <Typography variant='caption' display='block' gutterBottom>
                      {item.label}
                    </Typography>
                  </a>
                </Box>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor='left'
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {drawerList()}
      </Drawer>
      <div className="toolbar-spacing" />
    </>
  );
};

export default Header;
