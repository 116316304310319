import Footer from '../common/Footer/Footer';
import Header from '../common/Header/Header';
import TopContact from './Contact/Contact';
import Intro from './Intro/Intro';
import Service from './Service/Service';
import SliderComponent from './Slider/Slider';
import './Top.scss';
import TranslationTopComponent from './Translation/Translation';

const Top = () => {
    return (
        <>
            <Header />
            <SliderComponent />
            <Intro />
            <Service />
            <TranslationTopComponent />
            <TopContact />
            <Footer />
        </>
    )
}

export default Top;